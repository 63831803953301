<template>
  <div>
    <slot />

    <div
      aria-live="assertive"
      class="z-20 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <transition-group
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <UINotification
            v-for="notification in notifications.notifications"
            :key="notification.id"
            :headline="notification.headline"
            :body="notification.body"
            :level="notification.level"
            @close="notifications.remove(notification)"
          />
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import { UINotification } from "@ultrafedt/components";

useHead({
  htmlAttrs: {
    class: "h-full bg-orange-50",
  },
  bodyAttrs: {
    class: "h-full",
  },
  titleTemplate: "%s - Ultrafedt POS Auth",
});

const notifications = useNotifications();
</script>
